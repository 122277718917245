<template>
    <div
      class="onenot"
      v-if="notification.view"
    >
      <h3>{{ notification.title }}</h3>
      <p>{{ notification.text }}</p>
    </div>
  </template>
  
  <script>
  import { notification } from "@/logics/general";
  export default {
    setup() {
     
      return {notification};
    },
  };
  </script>
  <style scoped>
  .onenot {
    box-shadow: 2px 2px 10px 2px rgba(11, 10, 10, 0.2);
    box-shadow: 2px 2px 10px 2px rgb(11 10 10 / 20%);
    background: rgb(255 255 255 / 90%);
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    min-width: 250px;
    animation: scale-in-hor-right 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    backdrop-filter: blur(1px);
    position: fixed;
    top:10px;
    right: 10px;
    z-index: 9999999999;
  }
 
  @keyframes scale-in-hor-right {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  
  @keyframes scale-in-hor-right-end {
    0% {
      transform: translateY(0%);
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  }
  
  
  h3 {
    font-weight: 300;
    font-size: 15px;
  }
  p {
    font-weight: 200px;
  }
  
  .error {
    background-color: var(--red);
  }
  </style>
  