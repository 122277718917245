import axios from "@/utils/axios";
import router from "@/router";
import { ref, watch, reactive } from "vue";
const history = {};

class getBd {
  constructor() {
    this.limit = ref(20);
    this.skip = ref(0);
    this.filter = reactive(router.currentRoute.value.meta.viewdocument || {});
    this.data = ref([]);
    this.total = ref(0);
    this.viewpage = ref(0);
    this.collection = ref(router.currentRoute.value.meta.collection);
    this.textsearch = ref(null);
  }
  async get() {
    try {
      if(fullmetainfo().activite) return
      this.data.value = await axios.post("/bd/bd/", {
        collection: this.collection.value,
        limit: this.limit.value,
        skip: this.skip.value,
        filter: this.filter,
        populate: router.currentRoute.value.meta.populate,
        sort:router.currentRoute.value.meta.sort || {_id:-1}
      });
      this.total.value = this.data.value.allcount;
      return {
        data: this.data,
        limit: this.limit,
        skip: this.skip,
        filter: this.filter,
        total: this.total,
      };
    } catch (e) {
      console.log(e);
    }
  }
  changeFilter(d){

    this.filter = d
  }
  getValue() {
    return {
      limit: this.limit,
      skip: this.skip,
      filter: this.filter,
      total: this.total,
      viewpage: this.viewpage,
      textsearch: this.textsearch,
      data: this.data.value.data,
    };
  }
  watch() {
    watch([this.skip, this.limit], () => {
      this.get();
    });
  }
}

const create = () => {
  if (!history[router.currentRoute.value.meta.collection+router.currentRoute.value.name]) {
    history[router.currentRoute.value.meta.collection+router.currentRoute.value.name] = new getBd();
  }
  return history[router.currentRoute.value.meta.collection+router.currentRoute.value.name];
};

const metainfo = () => {
  var meta = router.currentRoute.value.meta.filter;
  if (meta) {
    return meta;
  } else {
    return null;
  }
};

const fullmetainfo = () => {
  var meta = router.currentRoute.value.meta;
  if (meta) {
    return meta;
  } else {
    return null;
  }
};

const collectionInfo = () => {
  var meta = router.currentRoute.value.meta.infocollection;
  if (meta) {
    return meta;
  } else {
    return null;
  }
};

const searchDocumentCollection = async (data) => {
  try {
    var d = await axios.post("/bd/bd/", data);
    return d;
  } catch (e) {
    console.log(e);
  }
};

export { metainfo, create, getBd, searchDocumentCollection, collectionInfo,fullmetainfo };
