<template>
        <transition name="slimeup">
        <div class="updateApp" v-if="updateApp">
            <p>Новая версия CRM нажмите кнопку обновить</p>
            <div class="button" @click="reload()">Обновить</div>
        </div>    
        </transition>
</template>
  
<script setup>
import { updateApp } from "@/logics/general";
const reload = () => window.location.reload()
</script>
<style scoped>
 .updateApp{
    height: 70px;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 10px;
    width: calc(100% - 20px);
    position: fixed;
    left: 0;
    z-index: 999;
    top: 58px;
    background: var(--white-color);
 }
</style>
  