<template>
     <div class="header">
        <div class="menuLLL nocopy" @click="openMenu = true">МЕНЮ</div>
        <div class="menuLLL nocopy" @click="router.go(-1)">НАЗАД</div>
        <p class="logo font">ПОДРУГА ПОЗАВИДУЕТ</p>
        <div class="namePatch">
            <p>{{ nameSs }}</p>
        </div>
        <div class="menuFuuls">
            <svg  @click="viewCamera = true" height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M417.5,160h-61.7c-32.1-36-42.2-48-54.5-48h-88.5c-12.3,0-22.2,12-54.5,48H145v-16h-34v16H97.5C79.9,160,64,173.2,64,190.7   v176c0,17.5,15.9,33.3,33.5,33.3h320c17.6,0,30.5-15.8,30.5-33.3v-176C448,173.2,435.1,160,417.5,160z M432,366.7   c0,9.3-6.2,17.3-14.5,17.3h-320c-8.7,0-17.5-8.7-17.5-17.3v-176c0-8.2,8.1-14.7,17.5-14.7h60.7h7.1l4.8-3.2c4-4.5,7.7-10,11.1-13.8   c11.3-12.7,19.5-21.7,25.3-26.9c4.7-4.2,6.2-4.1,6.2-4.1h88.5c0,0,1.6-0.1,6.7,4.5c6.1,5.5,14.7,16.5,26.6,29.8   c2.9,3.3,6,6.8,9.3,10.5l4.8,3.2h7.2h61.7c8.8,0,14.5,6,14.5,14.7V366.7z"/><path d="M256,189.5c-47.1,0-85.5,38.4-85.5,85.5s38.4,85.5,85.5,85.5s85.5-38.4,85.5-85.5S303.1,189.5,256,189.5z M256,344.5   c-38.4,0-69.5-31.1-69.5-69.5s31.1-69.5,69.5-69.5s69.5,31.1,69.5,69.5S294.4,344.5,256,344.5z"/><rect height="17" width="17" x="352" y="192"/><circle cx="256" cy="275" r="32"/></g></svg>
        </div>
        <div class="menuavatar">
            <svg viewBox="0 0 500 474.5" xmlns="http://www.w3.org/2000/svg"><defs></defs><title/><g data-name="Layer 2" id="Layer_2"><g data-name="Layer 1" id="Layer_1-2"><path class="cls-1" d="M500,250A250.28,250.28,0,0,1,368.85,470H131.15A250,250,0,1,1,500,250Z"/><path class="cls-2" d="M250,60h0a90,90,0,0,1,90,90V350a0,0,0,0,1,0,0H160a0,0,0,0,1,0,0V150A90,90,0,0,1,250,60Z"/><path class="cls-3" d="M400,333.23V470H100V333.23a52.75,52.75,0,0,1,42.39-51.72l20.13-4,33.3-6.66a276.76,276.76,0,0,1,108.36,0l36.18,7.23,17.25,3.45A52.75,52.75,0,0,1,400,333.23Z"/><path class="cls-3" d="M330,350v31.42A47.9,47.9,0,0,1,330,440v30"/><circle class="cls-3" cx="315" cy="175" r="15"/><circle class="cls-3" cx="185" cy="175" r="15"/><path class="cls-3" d="M304.18,270.83q-12-2.4-24.18-3.7V230H220v37.13q-12.15,1.32-24.18,3.7L170,276v4l80,90,80-90v-4Z"/><path class="cls-3" d="M310,130v60a60,60,0,0,1-60,60h0a60,60,0,0,1-60-60V130"/><path class="cls-3" d="M320,130s-50,0-70-20c-20,20-70,20-70,20"/><circle class="cls-1" cx="275" cy="165" r="5"/><circle class="cls-1" cx="225" cy="165" r="5"/><path class="cls-3" d="M270,200h0a20,20,0,0,1-40,0"/><path class="cls-3" d="M170,350v31.42A47.9,47.9,0,0,0,170,440v30"/><path class="cls-2" d="M250,324.5A54.56,54.56,0,0,0,304.5,270c0-1.25-.06-2.49-.14-3.71-3.06-.61-6.12-1.15-9.19-1.65a45.5,45.5,0,1,1-90.34,0c-3.07.51-6.13,1-9.19,1.65-.08,1.22-.14,2.46-.14,3.71A54.56,54.56,0,0,0,250,324.5Z"/></g></g></svg>
            <p>{{ user.firstname }} {{  user.lastname }}</p>
        </div>
     </div>
     <transition name="slimeright">
        <camera v-if="viewCamera" />
     </transition>
</template>

<script setup>
import {user,openMenu} from '../../logics/auth'
import {ref,watch,computed} from 'vue'
import camera from '@/components/general/camera.vue'
import { useRouter,useRoute } from "vue-router";
import { fullmetainfo } from "@/utils/bd/getCollection";
const router = useRouter();
const r = useRoute();

const nameSs = computed(() => {
  
    if(r?.meta?.infocollection?.name){
        return r.meta.infocollection.name
    }
    return ''
})

watch(() => router.currentRoute.value, 
        (newRoute) => {
          if(openMenu.value){
            openMenu.value = false
          }
});
const viewCamera = ref(false)
</script>

<style scoped>
.header{
    display: grid;
    grid-template-columns: auto auto auto 1fr auto auto;
    width: calc(100% - 20px);
    align-items: center;
    padding: 10px;
    gap:20px;
    background: var(--black-color);
    color: var(--white-color);
    
}
.logo{
    color: var(--white-color);
    font-size: 25px;
}
.menuavatar{
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    align-items: center;
}
.menuavatar svg{
    width: 40px;
}
.menuavatar p{
    color: var(--white-color);
}
.cls-1{fill:#62626a;}.cls-2{fill:#ff9e9d;}.cls-3{fill:#fff;stroke:#62626a;stroke-miterlimit:10;stroke-width:9px;}
.menuFuuls{}
.menuFuuls svg{
width: 40px;
height: 40px;
}
.menuFuuls svg path{
    fill:var(--white-color)
}
.menuFuuls svg circle{
    fill:none
}
.menuLLL{
    width: calc(100% - 20px);
    background: var(--white-color);
    color: var(--black-color);
    font-weight: 200;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    
}
.namePatch{

}
.namePatch p {
    color:var(--white-color)
}
</style>