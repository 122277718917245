import axios from "@/utils/axios";
import { data, save } from "@/utils/bd/getDocument";
import {setNotification} from "@/logics/general"
import router from "@/router";
import { ref } from "vue";

const change = ref(false);
const updateFullDocument = async () => {
  try {
    var update = await axios.post(
      `/bd/bd/updatefulldocument/${router.currentRoute.value.meta.collection}/${data.value._id}`,
      data.value
    );
    save.value = false;
    change.value = true;
    setTimeout(() => (change.value = false), 500);
    setNotification("Отлично","Документ изменен и сохранен");
  } catch (e) {
    console.log(e)
    if(router.currentRoute.value.meta.collection == 'client'){
      setNotification( "Ошибка","Попробуйте еще раз, возможно такой документ уже существует");
    }else{
    setNotification( "Ошибка","Попробуйте еще раз");
    }
  }
};


export { updateFullDocument, change };
