<template>
  <div>
    <AppLayout>
      <router-view />
    </AppLayout>
  </div>
</template>

<script>
export default {
  setup() {
    
  },
}
</script>

<style>
*{
     margin: 0;
     padding: 0;
     font-family: "conke"; 
 }

@font-face {
font-family: "zector"; 
src: url("/public/fonts/eurof35.ttf") format("truetype"); 
font-style: normal; 
font-display: swap;
font-weight: 100;
} 

@font-face {
font-family: "zector"; 
src: url("/public/fonts/eurof55.ttf") format("truetype"); 
font-style: normal; 
font-display: swap;
font-weight: 200;
} 
@font-face {
font-family: "zector"; 
src: url("/public/fonts/eurof75.ttf") format("truetype"); 
font-style: normal; 
font-display: swap;
font-weight: 300;
} 


@font-face {
font-family: "conke"; 
src: url("/public/fonts/l1.woff2") format("woff2"); 
font-style: normal; 
font-weight: 100;
font-display: swap;
} 

@font-face {
font-family: "conke"; 
src: url("/public/fonts/l2.woff2") format("woff2"); 
font-style: normal; 
font-weight: 200;
font-display: swap;
} 

@font-face {
font-family: "conke"; 
src: url("/public/fonts/l3.woff2") format("woff2"); 
font-style: normal; 
font-weight: 300;
font-display: swap;
} 


@font-face {
font-family: "conke"; 
src: url("/public/fonts/l4.woff2") format("woff2"); 
font-style: normal; 
font-weight: 400;
font-display: swap;
} 


@font-face {
font-family: "conke"; 
src: url("/public/fonts/l5.woff2") format("woff2"); 
font-style: normal; 
font-weight: bold;
font-display: swap;
} 

body{
  scroll-behavior: smooth;
}

html{
  background: var(--grey3-color);
}


.content {
    margin: 0 auto;
    width: 88%;
}

:root{
  --black-color:#363634;
  --white-color:#fff;
  --grey-color:#eaeaea;
  --grey2-color:#f4f4f4;
  --grey3-color:#F8F8F8;
  --grey4-color:#EEEEEE;
  --red-color:#ff0000;
  --green-color:green;
  --blackgrey-color: #cfcfcf;
  --boxshadowhover: 0px 0px 20px 0px rgba(0,0,0,.23);
}

h1,h2,h3,h4,h5{
    font-weight: 300;
    color: var(--black-color);
    font-size: 20px;
    padding: 10px 0px 14px;
}
p,span,a{
  font-size: 16px;
  font-weight: 200;
  color:var(--black-color);
}


input,
select,
textarea {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid var(--blackgrey-color);
  border-radius: 5px;
  transition: all 0.3s;
  background: var(--white-color);
  color: var(--black-color);
}
input:focus,
select:focus,
textarea:focus {
  box-shadow: var(--boxshadowhover);
}

input:focus-visible,
select:focus-visible,
textarea:focus-visible {
  outline: none;
  background:var(--white-color);
}

.button {
  background: var(--black-color);
  padding: 10px;
  color: var(--white-color);
  cursor: pointer;
  transition: 200ms;
  width: max-content;
  border-radius: 5px;
  font-weight: 300;
}
.button:hover {
  box-shadow: var(--boxshadowhover);
}


.blockinputp {
  display: grid;
  gap: 5px;
}
.blockinputp p {
  font-weight: 200;
  color: var(--black-color);
}

.font {
  font-family: 'zector';
}


.slimeleft-enter-active {
  transition: all 200ms ease;
}
.slimeleft-leave-active {
  transition: all 200ms;
}
.slimeleft-enter-from, .slimeleft-leave-to
{
  transform: translateX(-100%);
  opacity: 0;
}

.slimeright-enter-active {
  transition: all 200ms ease;
}
.slimeright-leave-active {
  transition: all 200ms;
}
.slimeright-enter-from, .slimeright-leave-to
{
  transform: translateX(100%);
  opacity: 0;
}


.slimedown-enter-active {
  transition: all 200ms ease;
}
.slimedown-leave-active {
  transition: all 200ms;
}
.slimedown-enter-from, .slimedown-leave-to
{
  transform: translateY(100%);
 
}



.slimeup-enter-active {
  transition: all 200ms ease;
}
.slimeup-leave-active {
  transition: all 200ms;
}
.slimeup-enter-from, .slimeup-leave-to
{
  transform: translateY(-100%);
 
}


.slimeopacity-enter-active {
  transition: all 200ms ease;
}
.slimeopacity-leave-active {
  transition: all 200ms;
}
.slimeopacity-enter-from, .slimeopacity-leave-to
{
  opacity: 0;
 
}
.buttonwidget {
  margin: 20px 0px;
  background: var(--input);
  height: 50px;
  display: grid;
  align-items: center;
  justify-items: center;
  font-weight: 200;
  color: var(--black);
  border: 1.5px dashed var(--blackgrey-color);
  border-radius: 10px;
  cursor: pointer;
  transition: 200ms;
}
.buttonwidget:hover {
  box-shadow: var(--boxshadowhover);
}

.nodisplay {
  display: none;
}
.widgetBlock{
     margin-bottom: 20px;
    background: var(--white-color);
    padding: 10px 10px 10px;
    border-radius: 5px;
    display: grid;
    gap: 20px;
}

.titlePages {
    margin-bottom: 20px;
    background: var(--white-color);
    padding: 10px 10px 10px;
    border-radius: 5px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr auto;
    align-items: center;
  }

  .titlePages p{

    font-weight: 300;
  }
  .titlePages span {
   
    font-weight: 200;
    cursor: pointer;
    transition: 200ms;
  }
  .titlePages span:hover{
    opacity: 0.5;
  }
.titlePagesActivity{
  background: var(--black-color);
}
.titlePagesActivity p,.titlePagesActivity span{
  color: var(--white-color);
}
.blockVIewN{
  transition: 500ms;
}
.blockVIewS{
    box-shadow: 0px 0px 14px 3px #c2c2c2;
    border-radius: 5px;
}

.titleSSs{
  font-weight: 300;
  font-size: 16px;
}

.openCloseButton{
  
}

.oneTechDes:last-child{
  padding-bottom: 20px !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track-piece {
  background: #ebe9f1;
}
::-webkit-scrollbar-thumb:vertical {
  height: 10px;
  background: var(--black-color);
  border-radius: 10px;
  transition: 200ms;
}
::-webkit-scrollbar-thumb:vertical:hover {
  background: var(--black-color);
  transition: 200ms;
}
::-webkit-scrollbar-thumb:horizontal {
  width: 10px;
  background: var(--black-color);
}
::-webkit-scrollbar-thumb:horizontal:hover {
  background: var(--black-color);
}

</style>