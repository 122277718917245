import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/client/client",
    name: "client-client",
    title: "Клиенты",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Клиенты",
        item: defineAsyncComponent(() =>
          import("@/components/blocks/client/item.vue")
        ),
      },
      collection: "client",
      populate: [],
      filter: {
        object: ["lastname", "phone","email","webpush"],
        text: "Поиск клиента",
        placeholder: "Введите номер телефона клиента",
        menufilter: [],
      },
    },
  },
  {
    path: "/client/client/:id",
    name: "client-client-id",
    title: "Клиенты",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "client",
      populate: [{path:'cardbonus',select:['name','cashback','frendcashback']}],
      template: {
        main: ['clientRendered','clientMylico','clientBonus'],
        sidebar: ['clientProfile'],
      },
    },
  },
  {
    path: "/client/zapisi",
    name: "client-zapisi",
    title: "Клиенты",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Записи",
        item: defineAsyncComponent(() =>
          import("@/components/blocks/client/zapisi.vue")
        ),
      },
      collection: "zapisi",
      activite:true,
      populate: [],
      filter: {
        object: [],
        text: "Поиск клиента",
        placeholder: "Введите номер телефона клиента",
        menufilter: [],
      },
    },
  },
  {
    path: "/rendered",
    name: "client-rendered",
    title: "Оказанные услуги",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Оказанные услуги",
        item: defineAsyncComponent(() =>
          import("@/components/blocks/rendered/full.vue")
        ),
      },
      collection:"rendered",
      activite:true,
      populate: [],
      filter: {
        object: [],
        text: "Поиск клиента",
        placeholder: "Введите номер телефона клиента",
        menufilter: [],
      },
    },
  },
  {
    path: "/bonus",
    name: "client-bonus",
    title: "История бонусов",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "История бонусов",
        item: defineAsyncComponent(() =>
          import("@/components/blocks/bonus/item.vue")
        ),
      },
      collection: "bonus",
      populate: [{path:'client',select:['firstname','lastname']}],
      filter: {
        object: [],
        text: "Поиск клиента",
        placeholder: "Введите номер телефона клиента",
        menufilter: [],
      },
    },
  },

  {
    path: "/cardbonus",
    name: "cardbonus",
    title: "Карты лояльности",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Карты лояльности",
        item: defineAsyncComponent(() =>
          import("@/components/blocks/client/cardbonusitem.vue")
        ),
      },
      collection: "cardbonus",
      populate: [],
      filter: {
        object: [],
        text: "Поиск клиента",
        placeholder: "Введите номер телефона клиента",
        menufilter: [],
      },
    },
  },
  {
    path: "/cardbonus/:id",
    name: "cardbonus-cardbonus-id",
    title: "Карты лояльности",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "cardbonus",
      populate: [],
      template: {
        main: ['generalName','cardbonusSetting','generalAddimage'],
        sidebar: [],
      },
    },
  },
];
