import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import AppLayout from "./layouts/AppLayout";
import './registerServiceWorker'
createApp(App)
  .use(router)
  .component("AppLayout", AppLayout)
  .mount("#app");
   createApp(App).config.globalProperties.$head = {
    title: 'СRM ПОДРУГА ПОЗАВИДУЕТ',
    meta: [
      {
        name: 'description',
        content: 'CRM ПОДРУГА ПОЗАВИДУЕТ',
      },
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico',
      },
    ],
  };