import clientBlocks from "./client"
import genaralBlocks from "./general"
import consumableBlocks from "./consumable"
import uslugiBlocks from "./uslugi"
import courseBlocks from "./course"
import cardbonusBlocks from "./cardbonus"
import vuduBlocks from "./vudu"
import techBlocks from "./techcart"
import docBlocks from "./documents"
import bookBlocks from "./book"
export default {
 ...clientBlocks,
 ...genaralBlocks,
 ...consumableBlocks,
 ...uslugiBlocks,
 ...courseBlocks,
 ...cardbonusBlocks,
 ...vuduBlocks,
 ...techBlocks,
 ...docBlocks,
 ...bookBlocks
};
