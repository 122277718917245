import axios from "axios";
import {preload} from '@/logics/general'
axios.defaults.baseURL = `${process.env.VUE_APP_API}`;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers["loading"] = true;
axios.interceptors.request.use(
  (config) => {
    if (config.headers.loading) {

      preload.value = true
    }
    return config;
  },
  (error) => {
    preload.value = false
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    preload.value = false
    return res.data;
  },
  (error) => {
    preload.value = false
    return Promise.reject(error);
  }
);

export default axios;
