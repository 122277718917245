<template>
  <component :is="layout">
    <slot />
  </component>
  <preload />
  <notification />
</template>

<script setup>
import AppLayoutDefault from "@/layouts/AppLayoutDefault"
import AppLayoutAuth from "@/layouts/AppLayoutAuth"
import { authUser } from "@/logics/auth"
import { computed,onMounted } from "vue";
import preload from '@/components/general/preload'
import notification from '@/components/general/notification'

    const layout = computed(() => {
      if (authUser.value) return AppLayoutDefault;
      return AppLayoutAuth;
    });
   
</script>

<style scoped>

</style>
