import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/consumable/consumable",
    name: "consumable-consumable",
    title: "Материалы",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Материалы",
        item: defineAsyncComponent(() =>
          import("@/components/blocks/consumable/item.vue")
        )
      },
      collection: "consumable",
      populate: [{path:'brend',select:['name']}],
      filter: {
        object: ["name"],
        text: "Поиск материала",
        placeholder: "Введите название",
        menufilter: [],
        component:[{path:'category',col:'catconsumable'}]
      },
    },
  },
  {
    path: "/consumable/consumable/:id",
    name: "consumable-consumable-id",
    title: "Материалы",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "consumable",
      populate: [],
      template: {
        main: ['generalName','specConsumable'],
        sidebar: ['changeCategory','changeBrend'],
      },
      options:{
        category:'catconsumable'
      }
      
    },
  },
  {
    path: "/consumable/catconsumable",
    name: "consumable-catconsumable",
    title: "Категории материалов",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Категории материалов",
        item: defineAsyncComponent(() =>import("@/components/blocks/consumable/itemcat.vue")),
      },
      collection: "catconsumable",
      populate: [],
      filter: {
        object: ["name"],
        text: "Поиск категории",
        placeholder: "Введите название",
        menufilter: []
      },
    },
  },
  {
    path: "/consumable/catconsumable/:id",
    name: "consumable-catconsumable-id",
    title: "Категория материалов",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "catconsumable",
      populate: [],
      template: {
        main: ['generalName'],
        sidebar: [],
      },
    },
  },
  {
    path: "/consumable/brendconsumable",
    name: "consumable-brendconsumable",
    title: "Бренды",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Бренды",
        item: defineAsyncComponent(() =>import("@/components/blocks/consumable/itembrend.vue")),
      },
      collection: "brendconsumable",
      populate: [],
      filter: {
        object: ["name"],
        text: "Поиск бренда",
        placeholder: "Введите название",
        menufilter: []
      },
    },
  },
  {
    path: "/consumable/brendconsumable/:id",
    name: "consumable-brendconsumable-id",
    title: "Бренд",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "brendconsumable",
      populate: [],
      template: {
        main: ['generalName'],
        sidebar: [],
      },
    },
  },
];
