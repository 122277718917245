import { defineAsyncComponent } from "vue";
export default [
      {
        path: "/document/soglashenie",
        name: "document-soglashenie",
        title: "Соглашения",
        component: () => import("../template/pages/templateCollection.vue"),
        meta: {
          infocollection: {
            name: "Соглашения",
            item: defineAsyncComponent(() =>import("@/components/blocks/generalitem/item.vue")),
          },
          collection: "soglashenie",
          populate: [],
          filter: {
            object: ["name"],
            text: "Поиск",
            placeholder: "Введите название",
            menufilter: []
          },
        },
      },
      {
        path: "/document/soglashenie/:id",
        name: "document-soglashenie-id",
        title: "Соглашения",
        component: () => import("../template/pages/templatePagesId.vue"),
        meta: {
          collection: "soglashenie",
          populate: [],
          template: {
            main: ['generalName','sogContent'],
            sidebar: [],
          },
        },
      },
      {
        path: "/document/techcarthelp",
        name: "document-techcarthelp",
        title: "Соглашения",
        component: () => import("../template/pages/templateCollection.vue"),
        meta: {
          infocollection: {
            name: "Технические карты",
            item: defineAsyncComponent(() =>import("@/components/blocks/generalitem/item.vue")),
          },
          collection: "techcarthelp",
          populate: [],
          filter: {
            object: ["name"],
            text: "Поиск",
            placeholder: "Введите название",
            menufilter: []
          },
        },
      },
      {
        path: "/document/techcarthelp/:id",
        name: "document-techcarthelp-id",
        title: "Соглашения",
        component: () => import("../template/pages/templatePagesId.vue"),
        meta: {
          collection: "techcarthelp",
          populate: [],
          template: {
            main: ['generalName','techPlay','generalContent'],
            sidebar: [],
          },
        },
      },
  ];