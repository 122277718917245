import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/uslugi/uslugi",
    name: "uslugi-uslugi",
    title: "Услуги",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Услуги",
        item: defineAsyncComponent(() =>
          import("@/components/blocks/uslugi/item.vue")
        ),
      },
      collection: "uslugi",
      populate: [],
      filter: {
        object: ["name"],
        text: "Поиск услуги",
        placeholder: "Введите название",
        menufilter: [],
        component:[{path:'category',col:'catuslugi'}]
      },
    },
  },
  {
    path: "/uslugi/uslugi/:id",
    name: "uslugi-uslugi-id",
    title: "Услуга",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "uslugi",
      populate: [{path:'techcart.compound.consumable',select:['price','name','brend','fullquantity','remainder','measure'],populate:{path:'brend',select:['name']}}],
      template: {
        main: ['generalName','generalAddimage','generalContent','uslugiDopConentent','protivBlock','recomendBlock','uslugiTechCart','generalSeo'],
        sidebar: ['generalStatus','changeCategory','soglBlock'],
      },
      options:{
        category:'catuslugi'
      }
    },
  },
  {
    path: "/uslugi/catuslugi",
    name: "uslugi-catuslugi",
    title: "Категории услуг",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Категории услуг",
        item: defineAsyncComponent(() =>import("@/components/blocks/uslugi/itemcat.vue")),
      },
      collection: "catuslugi",
      populate: [],
      filter: {
        object: ["name"],
        text: "Поиск услуги",
        placeholder: "Введите название",
        menufilter: [],
      },
    },
  },
  {
    path: "/uslugi/catuslugi/:id",
    name: "uslugi-catuslugi-id",
    title: "Услуга",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "catuslugi",
      populate: [],
      template: {
        main: ['generalName','generalAddimage','generalContent','generalSeo'],
        sidebar: ['generalStatus'],
      },
    },
  },
  {
    path: "/uslugi/course",
    name: "uslugi-course",
    title: "Курсы",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Курсы",
        item: defineAsyncComponent(() =>
          import("@/components/blocks/course/item.vue")
        ),
      },
      collection: "course",
      populate: [],
      filter: {
        object: ["name"],
        text: "Поиск курса",
        placeholder: "Введите название",
        menufilter: [],
      },
    },
  },
  {
    path: "/uslugi/course/:id",
    name: "uslugi-course-id",
    title: "Услуга",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "course",
      populate: [{path:'uslugi',select:['name','techcart']}],
      template: {
        main: ['generalName','generalAddimage','generalContent','uslugiDopConentent','sostavCourse','generalSeo'],
        sidebar: ['generalStatus'],
      },
    },
  },
];
