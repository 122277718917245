import { createRouter, createWebHistory } from "vue-router";
import { useCookies } from "vue3-cookies";
import { authUser,setUser } from "@/logics/auth"
const { cookies } = useCookies();
var user = cookies.get("user");



import generalRoute from "./general"
import clientRoute from "./client"
import consumableRoute from "./consumable"
import uslugiRoute from "./uslugi"
import siteRoute from "./site"
import bookRoute from "./book"
import documentRoute from "./document"

const routes = [
  ...generalRoute,
  ...clientRoute,
  ...consumableRoute,
  ...uslugiRoute,
  ...siteRoute,
  ...bookRoute,
  ...documentRoute
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0 };
      }
    },
  });

  router.beforeEach(async (to, from) => {
    if (!authUser.value) {
      if (user) {
        try {
          await setUser(user)
          return true;
        } catch (e) {
          cookies.remove("user");
          return { name: "auth" };
        }
      }
      if (to.meta.auth) {return true}
      return { name: "auth" };
    }
  });


export default router;
