<template>

    <div class="camera">
    
      <div class="menuBlock">
        <div class="cliPhoto" @click="captureImage"></div>
      </div>
        <div class="vidoBlock" ref="videoBlock">
          
         <div class="snap" v-if="snapView" :style="{width:snapW + 'px',height:snapH + 'px'}"><img :src='snap'></div>
         <video ref="video" autoplay muted></video>
        </div>    
      <div class="imgSblock">
        <select v-model="selectedCameraId">
             <option v-for="one in cameras" :key="one.id" :value="one.id">{{ one.label }}</option>
         </select>
       
      </div>
    </div>
    <transition name="slimeright">
      <div class="viewPhoto" v-if="imageUrl">
          <div class="menuPhoto">
            <div class="button" @click="saveImages()">Сохранить</div>
            <div class="button" style="justify-self: right;" @click="imageUrl = null">Отменить</div>
          </div>
          <img v-if="imageUrl" :src="imageUrl">
      </div>
    </transition>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted,watch } from 'vue';
  

  const video = ref(null);
  const imageUrl = ref(null);
  const snap = ref('/mask/1.svg')
  const snapW = ref(0)
  const snapH = ref(0)
  const videoBlock = ref(null)
  const snapView = ref(false)
  const cameras = ref([]);
  const selectedCameraId = ref(null);
  const currentStream =  ref(null)

  const getCameras = async () => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices(); 
    cameras.value = devices.filter(device => device.kind === 'videoinput').map(device => ({
      id: device.deviceId,
      label: device.label || `Камера ${devices.indexOf(device) + 1}`
    }));
      selectedCameraId.value = cameras.value[cameras.value.length - 1].id;
    } catch (error) {
      console.error('Ошибка получения списка камер:', error);
    }
 }
  
  const startCamera = async () => {
     try {
       snapView.value = false
       if (currentStream.value) {
            currentStream.value.getTracks().forEach(track => track.stop()); 
            currentStream.value = null;
       }
       
       const proVerkaCames = await navigator.mediaDevices.getUserMedia({ video: true,deviceId: selectedCameraId.value});
       const track = proVerkaCames.getVideoTracks()[0];
       const capabilities = track.getCapabilities();
       proVerkaCames.getTracks().forEach(track => track.stop()); 

       const stream = await navigator.mediaDevices.getUserMedia({ video: { 
        width: { ideal: capabilities.width.max }, 
        height: { ideal: capabilities.height.max },
        deviceId: selectedCameraId.value
       }});
     
       currentStream.value = stream;
       video.value.srcObject = stream;
       setTimeout(() => {
        snapW.value = videoBlock.value.clientWidth
        snapH.value = videoBlock.value.clientHeight
        snapView.value = true
        },1000)
     } catch (error) {
       console.error('Ошибка доступа к камере:', error);
     }
   };
   
  
  const captureImage = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = video.value.videoWidth / 4;
    canvas.height = video.value.videoHeight / 4;
    console.log(video)
    ctx.drawImage(video.value, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL('image/jpeg',1.0);
    imageUrl.value = dataUrl;
  };
  
  const saveImages = () => {
    var link = document.createElement("a");
    link.href = imageUrl.value;
    link.download = "myImage.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    imageUrl.value = null
  }

  onMounted(async () => {
    await getCameras();
    await startCamera();
  });
  
  /*onUnmounted(() => {
    if (video.value.srcObject) {
      const tracks = video.value.srcObject.getTracks();
      tracks.forEach(track => track.stop());
    }
  }); */ 

  watch(selectedCameraId, () => {
     startCamera(); 
  });
  </script>

  <style scoped>
  .camera{
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    height: 100%;
    z-index: 999;
    display: grid;
    align-items: center;
    justify-items: center;
    background: var(--black-color);
    grid-template-columns: auto 1fr 200px;
    padding: 20px;

  }
  .snap{
    position: absolute;

  }
  .snap img{
    width: 100%;
    height: calc(100% - 50px);
    padding-top: 20px;
  }
  .vidoBlock{
    width: 50%;
    height: 50%;
  }
  .camera video{
    width: 100%;
    height: 100%;
  }
  .cliPhoto{
    width: 50px;
    height: 50px;
    background: red;
    border-radius: 100%;
  }
  .imgSblock img{
    width: 100%;
  }
  .menuPhoto{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:20px;
    width: 100%;
  }

  .viewPhoto{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    display: grid;
    align-items: center;
    justify-items: center;
    background: var(--black-color);
    grid-template-rows: 50px 1fr;
  }
  </style>