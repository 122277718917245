import { defineAsyncComponent } from "vue";

export default{
    generalName: defineAsyncComponent(() => import("./name")),
    changeCategory: defineAsyncComponent(() => import("./changeCategory")),
    generalSeo: defineAsyncComponent(() => import("./seo")),
    generalContent: defineAsyncComponent(() => import("./content")),
    generalAddimage: defineAsyncComponent(() => import("./addimage")),
    generalStatus: defineAsyncComponent(() => import("./status")),
    generalDescription: defineAsyncComponent(() => import("./description")),
}