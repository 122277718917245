<template>
   <transition name="slimeup">
    <div class="twobutton" v-if="save">
      <div class="savebutton red" @click="save = false">Отменить</div>
      <div class="savebutton" @click="updateFullDocument">Сохранить</div>
    </div>
  </transition>
  </template>
  
  <script>
  import { save } from "@/utils/bd/getDocument";
  import { updateFullDocument } from "@/utils/bd/updateDocument";
  export default {
    setup() {
      return { save, updateFullDocument };
    },
  };
  </script>
  <style scoped>
  .savebutton {
    font-weight: 300;
    background: var(--green-color);
    color: var(--white-color);
    padding: 10px 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .twobutton {
    position: fixed;
    right: 18%;
    top: 10px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    z-index: 99999;
  }
  
  .red {
    background: var(--red-color);
  }
  </style>
  