import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/site/article",
    name: "site-article",
    title: "Статьи",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Статьи",
        item: defineAsyncComponent(() =>
          import("@/components/blocks/site/articleitem.vue")
        )
      },
      collection: "article",
      populate: [],
      filter: {
        object: ["name"],
        text: "Поиск статьи",
        placeholder: "Введите название",
        menufilter: [],
        component:[]
      },
    },
  },
  {
    path: "/site/article/:id",
    name: "site-article-id",
    title: "Материалы",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "article",
      populate: [],
      template: {
        main: ['generalName','generalAddimage','generalContent','generalSeo'],
        sidebar: ['generalStatus','changeCategory'],
      },  
      options:{
        category:'catarticle'
      }
      
    },
  },
  {
    path: "/site/catarticle",
    name: "site-catarticle",
    title: "Категории статей",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Категории статей",
        item: defineAsyncComponent(() =>import("@/components/blocks/site/catitemcat.vue")),
      },
      collection: "catarticle",
      populate: [],
      filter: {
        object: ["name"],
        text: "Поиск категории",
        placeholder: "Введите название",
        menufilter: []
      },
    },
  },
  {
    path: "/site/catarticle/:id",
    name: "site-catarticle-id",
    title: "Категория статей",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "catarticle",
      populate: [],
      template: {
        main: ['generalName','generalAddimage','generalContent','generalSeo'],
        sidebar: [],
      },
    },
  },
  {
    path: "/site/page",
    name: "site-page",
    title: "Страницы",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Страницы",
        item: defineAsyncComponent(() =>
          import("@/components/blocks/site/pageitem.vue")
        )
      },
      collection: "page",
      populate: [],
      filter: {
        object: ["name"],
        text: "Поиск cтраниц",
        placeholder: "Введите название",
        menufilter: [],
        component:[]
      },
    },
  },
  {
    path: "/site/page/:id",
    name: "site-page-id",
    title: "Страницы",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "page",
      populate: [],
      template: {
        main: ['generalName','generalAddimage','generalContent','generalSeo'],
        sidebar: ['generalStatus'],
      }
    },
  },
  {
    path: "/site/recomend",
    name: "site-recomend",
    title: "Рекомендации",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Рекомендации",
        item: defineAsyncComponent(() =>
          import("@/components/blocks/site/recomenditem.vue")
        )
      },
      collection: "recomend",
      populate: [],
      filter: {
        object: ["name"],
        text: "Поиск статьи",
        placeholder: "Введите название",
        menufilter: [],
        component:[]
      },
    },
  },
  {
    path: "/site/recomend/:id",
    name: "site-recomend-id",
    title: "Рекомендации",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "recomend",
      populate: [],
      template: {
        main: ['generalName','generalAddimage','generalContent','generalSeo'],
        sidebar: ['generalStatus','changeCategory'],
      },
      options:{
        category:'catrecomend'
      }
    },
  },
  {
    path: "/site/catrecomend",
    name: "site-catrecomend",
    title: "Категории рекомендаций",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Категории рекомендаций",
        item: defineAsyncComponent(() =>import("@/components/blocks/site/catitemrecomm.vue")),
      },
      collection: "catrecomend",
      populate: [],
      filter: {
        object: ["name"],
        text: "Поиск категории",
        placeholder: "Введите название",
        menufilter: []
      },
    },
  },
  {
    path: "/site/catrecomend/:id",
    name: "site-catrecomend-id",
    title: "Категория рекомендаций",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "catrecomend",
      populate: [],
      template: {
        main: ['generalName','generalAddimage','generalContent','generalSeo'],
        sidebar: [],
      },
    },
  },
];
