import { defineAsyncComponent } from "vue";
export default [
    {
      path: "/book/book",
      name: "book-book",
      component: () => import("../components/blocks/book/template.vue"),
      meta: {
        layout: "AppLayoutAuth",
        auth: true,
      },
    },
    {
        path: "/book/bookpurpose",
        name: "book-bookpurpose",
        title: "Справочник целей",
        component: () => import("../template/pages/templateCollection.vue"),
        meta: {
          infocollection: {
            name: "Справочник целей",
            item: defineAsyncComponent(() =>import("@/components/blocks/book/purposesitem.vue")),
          },
          collection: "bookpurpose",
          populate: [],
          filter: {
            object: ["name"],
            text: "Поиск цели",
            placeholder: "Введите название",
            menufilter: []
          },
        },
      },
      {
        path: "/book/bookpurpose/:id",
        name: "book-bookpurpose-id",
        title: "Цель",
        component: () => import("../template/pages/templatePagesId.vue"),
        meta: {
          collection: "bookpurpose",
          populate: [],
          template: {
            main: ['generalName','generalContent','bookUslugi'],
            sidebar: ['generalStatus'],
          },
        },
      },
      {
        path: "/book/bookvudu",
        name: "book-bookvudu",
        title: "Справочник лампы вуду",
        component: () => import("../template/pages/templateCollection.vue"),
        meta: {
          infocollection: {
            name: "Справочник лампы вуду",
            item: defineAsyncComponent(() =>import("@/components/blocks/book/vuduitem.vue")),
          },
          collection: "bookvudu",
          populate: [],
          filter: {
            object: ["name"],
            text: "Поиск",
            placeholder: "Введите название",
            menufilter: []
          },
        },
      },
      {
        path: "/book/bookvudu/:id",
        name: "book-bookvudu-id",
        title: "Справочник лампы вуду",
        component: () => import("../template/pages/templatePagesId.vue"),
        meta: {
          collection: "bookvudu",
          populate: [],
          template: {
            main: ['generalName','vuduColor','generalContent'],
            sidebar: [],
          },
        },
      },
      {
        path: "/book/booksoskozi",
        name: "book-booksoskozi",
        title: "Состояния кожи",
        component: () => import("../template/pages/templateCollection.vue"),
        meta: {
          infocollection: {
            name: "Состояния кожи",
            item: defineAsyncComponent(() =>import("@/components/blocks/generalitem/item.vue")),
          },
          collection: "booksoskozi",
          populate: [],
          filter: {
            object: ["name"],
            text: "Поиск состояния",
            placeholder: "Введите название",
            menufilter: []
          },
        },
      },
      {
        path: "/book/booksoskozi/:id",
        name: "book-booksoskozi-id",
        title: "Состояния кожи",
        component: () => import("../template/pages/templatePagesId.vue"),
        meta: {
          collection: "booksoskozi",
          populate: [],
          template: {
            main: ['generalName','generalContent','bookUslugi'],
            sidebar: ['generalStatus'],
          },
        },
      },
      {
        path: "/book/bookprotiv",
        name: "book-bookprotiv",
        title: "Противопоказания",
        component: () => import("../template/pages/templateCollection.vue"),
        meta: {
          infocollection: {
            name: "Противопоказания",
            item: defineAsyncComponent(() =>import("@/components/blocks/generalitem/item.vue")),
          },
          collection: "bookprotiv",
          populate: [],
          filter: {
            object: ["name"],
            text: "Поиск противопоказаний",
            placeholder: "Введите название",
            menufilter: []
          },
        },
      },
      {
        path: "/book/bookprotiv/:id",
        name: "book-bookprotiv-id",
        title: "Противопоказания",
        component: () => import("../template/pages/templatePagesId.vue"),
        meta: {
          collection: "bookprotiv",
          populate: [],
          template: {
            main: ['generalName','generalContent'],
            sidebar: ['generalStatus'],
          },
        },
      },
      {
        path: "/book/bookzabol",
        name: "book-bookzabol",
        title: "Заболевания кожи",
        component: () => import("../template/pages/templateCollection.vue"),
        meta: {
          infocollection: {
            name: "Заболевания кожи",
            item: defineAsyncComponent(() =>import("@/components/blocks/generalitem/item.vue")),
          },
          collection: "bookzabol",
          populate: [],
          filter: {
            object: ["name"],
            text: "Заболевания кожи",
            placeholder: "Введите название",
            menufilter: []
          },
        },
      },
      {
        path: "/book/bookzabol/:id",
        name: "book-bookzabol-id",
        title: "Заболевания кожи",
        component: () => import("../template/pages/templatePagesId.vue"),
        meta: {
          collection: "bookzabol",
          populate: [],
          template: {
            main: ['generalName','generalContent'],
            sidebar: ['generalStatus'],
          },
        },
      },
      {
        path: "/book/bookhron",
        name: "book-bookhron",
        title: "Хронические заболевания",
        component: () => import("../template/pages/templateCollection.vue"),
        meta: {
          infocollection: {
            name: "Хронические заболевания",
            item: defineAsyncComponent(() =>import("@/components/blocks/generalitem/item.vue")),
          },
          collection: "bookhron",
          populate: [],
          filter: {
            object: ["name"],
            text: "Хронические заболевания",
            placeholder: "Введите название",
            menufilter: []
          },
        },
      },
      {
        path: "/book/bookhron/:id",
        name: "book-bookhron-id",
        title: "Хронические заболевания",
        component: () => import("../template/pages/templatePagesId.vue"),
        meta: {
          collection: "bookhron",
          populate: [],
          template: {
            main: ['generalName','generalContent'],
            sidebar: ['generalStatus'],
          },
        },
      },
      {
        path: "/book/booktypekozi",
        name: "book-booktypekozi",
        title: "Тип кожи",
        component: () => import("../template/pages/templateCollection.vue"),
        meta: {
          infocollection: {
            name: "Хронические заболевания",
            item: defineAsyncComponent(() =>import("@/components/blocks/generalitem/item.vue")),
          },
          collection: "booktypekozi",
          populate: [],
          filter: {
            object: ["name"],
            text: "Тип кожи",
            placeholder: "Введите название",
            menufilter: []
          },
        },
      },
      {
        path: "/book/booktypekozi/:id",
        name: "book-booktypekozi-id",
        title: "Тип кожи",
        component: () => import("../template/pages/templatePagesId.vue"),
        meta: {
          collection: "booktypekozi",
          populate: [],
          template: {
            main: ['generalName','generalContent'],
            sidebar: ['generalStatus'],
          },
        },
      },
      {
        path: "/book/booktagclient",
        name: "book-booktagclient",
        title: "Теги клиентов",
        component: () => import("../template/pages/templateCollection.vue"),
        meta: {
          infocollection: {
            name: "Теги клиентов",
            item: defineAsyncComponent(() =>import("@/components/blocks/generalitem/item.vue")),
          },
          collection: "booktagclient",
          populate: [],
          filter: {
            object: ["name"],
            text: "Теги клиентов",
            placeholder: "Введите название",
            menufilter: []
          },
        },
      },
      {
        path: "/book/booktagclient/:id",
        name: "book-booktagclient-id",
        title: "Теги клиентов",
        component: () => import("../template/pages/templatePagesId.vue"),
        meta: {
          collection: "booktagclient",
          populate: [],
          template: {
            main: ['generalName','generalContent'],
            sidebar: ['generalStatus'],
          },
        },
      },
  ];