import {ref,computed} from 'vue'
import axios from "@/utils/axios";

const user = ref({})

const setUser = async (token) => {
  try{
    var data = await axios.post("/crm/user/token",{ token: token });
    user.value = data
  }catch(e){
    user.value = {}
  }

}

const openMenu = ref(false)

const authUser = computed(() => !!Object.keys(user.value).length)


const menuUser = [
  {
    path: "index",
    name: "Статистика",
    icon:'an.svg'
  },
  {
    path: "client-zapisi",
    name: "Записи",
    icon:'zapis.svg'
  },
  {
    name: "Оказанные услуги",
    path: "client-rendered",
    icon:'analyt.svg'
 
  },
  {
    path: "client",
    name: "Клиенты",
    icon:null,
    children: [
      { path: "client-client", name: "Клиенты",icon:'client.svg'},
      { path: "client-cardclient", name: "Карты клиентов",icon:'course.svg'},
      { path: "client-purpose", name: "Цели клиентов",icon:'bonus.svg'},
      { path: "client-bonus", name: "История бонусов",icon:'bonus.svg'},
    ],
  },
  {
    path: "uslugi",
    name: "Список услуг",
    icon:null,
    children: [
      { path: "uslugi-uslugi", name: "Услуги",icon:'uslugi.svg'},
      { path: "uslugi-course", name: "Формулы",icon:'course.svg'},
      { path: "uslugi-catuslugi", name: "Категории",icon:'catuslugi.svg'},
    ],
  },
  {
    path: "consumable",
    name: "Расходники",
    icon:null,
    children: [
      { path: "consumable-consumable", name: "Материалы",icon:'cons.svg'},
      { path: "consumable-catconsumable", name: "Категории",icon:'conscat.svg'},
      { path: "consumable-brendconsumable", name: "Бренды",icon:'brand.svg'}
    ],
  },
  {
    path: "documents",
    name: "Документы",
    icon:null,
    children: [
      {path: "document-soglashenie",name: "Соглашения",icon:'cardbonus.svg'},
      {path: "document-techcarthelp",name: "Тех.карты",icon:'cardbonus.svg'},
    ],
  },
  
 
  {
    path: "site",
    name: "Сайт",
    icon:null,
    children: [
      { path: "site-article", name: "Статьи",icon:'article.svg'},
      { path: "site-page", name: "Страницы",icon:'page.svg'},
      { path: "site-gallery", name: "Галерея",icon:'gallery.svg'},
      { path: "site-catarticle", name: "Категории статей",icon:'articlecat.svg'},
      { path: "site-partners", name: "Партнеры",icon:'articlecat.svg'}
    ],
  },
  {
    path: "recomend",
    name: "Рекомендации",
    icon:null,
    children: [
      { path: "site-recomend", name: "Статьи",icon:'article.svg'},
      { path: "site-catrecomend", name: "Категории",icon:'articlecat.svg'}
    ],
  },
  {
    path: "shop",
    name: "Магазин",
    icon:null,
    children: [
      { path: "shop-order", name: "Заказы",icon:'articlecat.svg'},
      { path: "shop-payment", name: "Оплаты",icon:'articlecat.svg'},
      { path: "shop-product", name: "Товары",icon:'article.svg'},
      { path: "shop-category", name: "Категории",icon:'articlecat.svg'}
    ],
  },
  {
    path: "certificates",
    name: "Сертификаты",
    icon:'cardbonus.svg'
  },
  {
    path: "cardbonus",
    name: "Карты лояльности",
    icon:'cardbonus.svg'
  },
  {
    path: "spravochniki",
    name: "Cправочники",
    icon:'guid.svg',
    children: [
      {
        path: "book-bookpurpose",
        name: "Цели",
        icon:'guid.svg'
      },
      {
        path: "book-bookprotiv",
        name: "Противопоказания",
        icon:'guid.svg'
      },
      {
        path: "book-booksoskozi",
        name: "Cостояния кожи",
        icon:'guid.svg'
      },
      {
        path: "book-bookzabol",
        name: "Заболевания кожи",
        icon:'guid.svg'
      },
      {
        path: "book-booktypekozi",
        name: "Тип кожи",
        icon:'guid.svg'
      },
      {
        path: "book-bookhron",
        name: "Хрон.заболевания",
        icon:'guid.svg'
      },
      {
        path: "book-bookvudu",
        name: "Вуду",
        icon:'guid.svg'
      },
      {
        path: "book-booktagclient",
        name: "Теги клиентов",
        icon:'guid.svg'
      }
    ],
  },
  {
    path: "generalfile",
    name: "Общие файлы",
    icon:'data.svg'
  },
]

export {
    authUser,
    setUser,
    user,
    menuUser,
    openMenu
  }