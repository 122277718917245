export default [
    {
      path: "/",
      name: "index",
      title: "Административная панель Подруга позавидует",
      component: () => import("../views/index.vue"),
    },
    {
      path: "/auth",
      name: "auth",
      component: () => import("../views/auth.vue"),
      meta: {
        layout: "AppLayoutAuth",
        auth: true,
      },
    },
    {
      path: "/generalfile",
      name: "generalfile",
      component: () => import("../components/blocks/general/generalfile.vue"),
      meta: {
        layout: "AppLayoutAuth",
        auth: true,
      },
    }
  ];