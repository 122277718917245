import {ref} from 'vue'

const preload = ref(false)
const updateApp = ref(false)
const notification = ref({
    view:false,
    title:'',
    text:''
})

const setNotification = (t,te) => {
    notification.value = {
        view:true,
        title:t,
        text:te
    }
    setTimeout(() => {
        notification.value = {
            view:false,
            title:'',
            text:''
        }  
    }, 2500); 
}

const createNormData = (data) => {
    if(data){
    var x = data.split('T')[0]
    x = x.split('-')
    return `${x[2]}-${x[1]}-${x[0]}`
    }
    return ' '

}

const menuBooks = [
  
    {name:'Типы кожи',endpoint:'typekozi'},
    {name:'Хронические заболевания',endpoint:'zabolevania'}
]

const purStatus = {
    new:'Новая'
}

export {
    preload,
    setNotification,
    notification,
    createNormData,
    updateApp,
    menuBooks,
    purStatus,
    
}